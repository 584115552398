import React from "react";
import {IPTSession} from "@/types/type.ts";
import moment from "moment-timezone";
import {Header} from "@/components/commons/Header.tsx";
import whatsapp from "@/assets/images/whatsapp.svg";
import facebook from "@/assets/images/facebook.svg";
import sms from "@/assets/images/sms.svg";
import email from "@/assets/images/gmail.svg";

interface Props {
    ptSessionSelected: IPTSession | null;
    referralCode: string;
}

export const Confirm: React.FC<Props> = ({ptSessionSelected, referralCode}) => {
    const linkDownloadApp = import.meta.env.VITE_LINK_DOWNLOAD_APP ?? '/'
    const referralMessage = "Hey! I’ve been doing small group PT sessions with Brawn, come join me! Sign up using my referral link here and we’ll both get a month free.";
    const referralLink = window.location.origin + `?referral_code=${referralCode}`;
    const messageEncoded = encodeURIComponent(referralMessage + "\n\n");
    // useEffect(() => {
    //     localStorage.clear()
    // }, []);

    return (
        <>
            <Header title={"Booking confirmed"} subtitle={"You’re all set! Get ready for your small group personal training session."}/>
            <div>
                <p className="text-lg font-bold mt-4">Your session</p>
                {
                    ptSessionSelected && (
                        <>
                            <p className="mt-3 text-sm">
                                {moment(ptSessionSelected.date).utc().format("dddd")},
                                {` `}
                                {moment(ptSessionSelected.date).utc().format("h:mma")}
                            </p>
                            <p className="text-xs">
                                {ptSessionSelected.creator?.name}
                            </p>
                        </>
                    )
                }
                <div className="border-t my-4"/>
            </div>
            <div className={"bg-no-repeat bg-cover px-3 py-5 my-4"}
                 style={{backgroundImage: "linear-gradient(180deg, rgba(31, 33, 39, 0.25) 0%, #1F2127 100%), url('/refer_friend.jpg')", backgroundPosition: "center"}}>
                <h3 className={'font-bold text-lg mt-16 mb-2'}>Bring a friend!</h3>
                <p className={'leading-5'}>Share the easy booking link below with a friend and have more fun training together.</p>
            </div>
            <p className={'mb-3'}>Share via</p>
            <div className={'mb-6'}>
                <a href={`sms:?body=${referralMessage + "\n\n" + referralLink}`} target="_blank" className="mx-2 inline-block" title="Share via SMS">
                    <img src={sms} alt="Share via SMS"/>
                </a>

                <a href={`mailto:?body=${referralMessage + "\n\n" + referralLink}&subject=I think you'll love Brawn!`} target="_blank"
                   className="mx-2 inline-block"
                   title="Share via Email">
                    <img src={email} alt="Share via Email"/>
                </a>

                <a href={`https://wa.me/?text=${messageEncoded + referralLink}`} target="_blank" className="mx-2 inline-block"
                   title="Share on WhatsApp">
                    <img src={whatsapp} alt="Share on WhatsApp"/>
                </a>

                <a href={`https://www.facebook.com/sharer/sharer.php?u=${referralLink}`} target="_blank" className="mx-2 inline-block"
                   title="Share on Facebook"><img src={facebook} alt="Share on Facebook"/></a>

                {/*<a href={`https://twitter.com/intent/tweet?url=${referralLink}&text=${referralMessage}`} target="_blank" className="mx-2 inline-block"*/}
                {/*   title="Share on X">*/}
                {/*    <img src={twitter} alt="Share on Twitter"/>*/}
                {/*</a>*/}


            </div>
            <div className={'flex'}>
                <input className={`rounded-xl p-4 border focus:outline-0 flex-1 mr-3 bg-white text-gray-900`}
                       type="text" name={'referralLink'} value={referralLink} readOnly/>
                <button className={'bg-white px-6 py-2 text-center text-black rounded-xl font-bold'} onClick={() => {
                    navigator.clipboard.writeText(referralLink);
                    alert("Copied to clipboard");
                }}>Copy link
                </button>
            </div>

            <div className={'border-t my-3'}/>
            <div className={'flex-grow'}>
                <p className="mt-8 text-base">
                    We’ve also sent you a confirmation email with some more information on
                    where to go and how to prepare for your session.
                </p>
                <p className="mt-8 text-base">
                    In the meantime, you can download the Brawn app by clicking the link
                    below - you’ll need it to see your session plan and track your
                    progress!{" "}
                </p>

                <a href={linkDownloadApp} target={"_blank"}
                   className={`block mt-8 w-full text-center text-white py-2 rounded 
                             bg-[#ec6730] cursor-pointer font-bold
                             hover:bg-red-700`}>
                    Download the app
                </a>

                <button className={`block mt-8 w-full text-center text-white py-2 rounded bg-[#54B4B8] cursor-pointer font-bold hover:bg-[#00a3a8]`} onClick={() => {
                    localStorage.clear();
                    window.location.href = "/";
                }}>
                    Start over
                </button>
            </div>
        </>
    )
}
