import {useEffect, useState} from "react";
import {getPTSessions} from "@/apis/api.tsx";
import {IGroup, IPTSession, ItemSession} from "@/types/type.ts";
import moment from 'moment-timezone';
import {LiaTimesSolid} from "react-icons/lia";
import {SessionDateBlock} from "@/components/session-date-block.tsx";
import {Loader} from "@/components/commons/Loader.tsx";
import {SessionFilter} from "@/components/session-filter.tsx";
import {Header} from "@/components/commons/Header.tsx";

interface Props {
    nextStep: (ptSessionSelected: IPTSession) => void,
    group: IGroup,
    selectedPTSession?: IPTSession | null
}

const Booking = ({nextStep, group, selectedPTSession}: Props) => {
    const linkToWaitList = import.meta.env.VITE_LINK_TO_WAITLIST ?? '/'
    const [ptSessions, setPtSessions] = useState<ItemSession[]>([])
    // const [ptId, setPtId] = useState<number | null>(null)
    const [ptSessionSelected, setPtSessionSelected] = useState<IPTSession | null>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (selectedPTSession) {
            setPtSessionSelected(selectedPTSession)
        }
    }, [selectedPTSession]);

    useEffect(() => pullPTSessions(group.id), [group]);

    const pullPTSessions = (groupId: number, pt?: number | null) => {
        if (loading) return;
        setLoading(true)
        getPTSessions(groupId, pt)
            .then((result) => {
                if (result) {
                    const masterSalve: ItemSession[] = []
                    result.forEach((item: IPTSession) => {
                        const date = moment(item.date.toString()).utc().format('YYYY-MM-DD');
                        const index = masterSalve.findIndex(x => x.date == date)
                        if (index !== -1) {
                            masterSalve[index].sessions.push(item);
                        } else {
                            masterSalve.push({
                                date: date,
                                sessions: [item]
                            })
                        }
                    })
                    setPtSessions(masterSalve)
                }
            })
            .catch((e) => console.log(e))
            .finally(() => setLoading(false))
    }

    const handleFilters = (ptId: number | null) => {
        // setPtId(ptId);
        setPtSessionSelected(null);
        pullPTSessions(group.id, ptId)
    };

    let sessions;
    if (ptSessions && ptSessions.length > 0) {
        sessions = ptSessions.map((item: ItemSession, key) => <SessionDateBlock key={key} item={item} sessions={item.sessions}
                                                                                onClick={(session) => setPtSessionSelected(session !== ptSessionSelected ? session : null)}
                                                                                selectedPTSession={ptSessionSelected}/>);
    } else {
        sessions = <p className={`text-center text-[#8f8c8c]`}>No sessions available</p>;
    }

    return (
        <>
            <Header title={"Pick the start date for your first session"}
                    subtitle={"Everyone starts with 1 session per week. You can always add more sessions or change your time later."}/>
            <div className={`mt-5 flex-1`}>
                <SessionFilter groupId={group.id} applyFilter={handleFilters}/>
                <div>
                    {loading ? <Loader/> : <div>{sessions}</div>}
                </div>
                <p className={`text-sm mb-3 mt-8`}>
                    No times available to suit you? <a href={linkToWaitList} target={"_blank"} className={`font-semibold underline`}>Click here</a> to join the <a
                    href={linkToWaitList} target={"_blank"} className={`font-semibold underline`}>waitlist</a> and request other slots.
                </p>
            </div>
            {
                (!!ptSessionSelected && !!group) && (
                    <div className={`sticky bottom-0 left-0 bg-[#54B4B8] 
                        pt-2 pb-5 w-full md:w-[529px] md:px-5 
                        mx-auto rounded-t-lg`}>
                        <div className={`mt-5`}>
                            <div className={`flex justify-between items-center border bg-[#54B4B8] 
                                    rounded-lg p-2`}>
                                    <span className={`text-xs`}>
                                        {moment(ptSessionSelected.date).utc().format("dddd, HH:mm")} with {ptSessionSelected.creator?.name}
                                    </span>
                                <button onClick={() => setPtSessionSelected(null)}>
                                    <LiaTimesSolid className={`w-5 h-5 font-thin`}/>
                                </button>
                            </div>
                        </div>

                        <button className={`mt-5 w-full text-center text-white py-2 rounded-lg 
                                 bg-[#ec6730] cursor-pointer font-bold
                                 disabled:bg-black`}
                                onClick={() => nextStep(ptSessionSelected)}
                        >
                            Proceed to booking
                        </button>
                    </div>
                )
            }
        </>
    )
}

export default Booking;
