import {IPTSession, ISetupIntent, ISubscriptionPreview, IUser, OrderParams} from "@/types/type.ts";
import moment from "moment-timezone";
import {useCallback, useEffect, useState} from "react";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {createOrder, getSetUpIntent, getSubscriptionPreview} from "@/apis/api.tsx";
import {Checkout} from "@/components/checkout.tsx";
import {FaSpinner} from "react-icons/fa6";
import {Header} from "@/components/commons/Header.tsx";

interface PaymentProps {
    nextStep: () => void,
    ptSession: IPTSession,
    user: IUser,
    setupIntentId: string | null,
    campaignSource?: string | null,
    campaignMedium?: string | null,
    campaignName?: string | null,
    referralCode?: string | null
}

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
export const Payment = ({nextStep, ptSession, user, setupIntentId, campaignSource, campaignName, campaignMedium, referralCode}: PaymentProps) => {
    const [orderError, setOrderError] = useState<string | null>(null);
    const [discountCode, setDiscountCode] = useState(referralCode ?? "");
    const [loading, setLoading] = useState(false);
    const [subscriptionPreview, setSubscriptionPreview] = useState<ISubscriptionPreview | null>(null);
    const [transactionId, setTransactionId] = useState('');
    const [setupIntent, setSetupIntent] = useState<ISetupIntent | null>(null);
    const [placingOrder, setPlacingOrder] = useState(false);
    const [discountFormSubmitted, setDiscountFormSubmitted] = useState(false);
    const [fetchingSetupIntent, setFetchingSetupIntent] = useState(false);

    const placeOrder = useCallback(() => {
        if (ptSession == null) {
            console.log('ptSession is null');
            return;
        }
        if (transactionId !== "") {
            console.log('transactionId is not null', transactionId);
            return;
        }
        if (placingOrder) {
            return;
        }
        let code: string | null = discountCode;
        // if discount code is not set in the component, we'll have to try and fetch it from the url redirect
        if (code.length <= 0) {
            const queryParameters = new URLSearchParams(window.location.search);
            code = queryParameters.get("discount_code");
            if (code && !discountCode) {
                setDiscountCode(code);
            }
        }
        const orderParams: OrderParams = {
            items: [import.meta.env.VITE_ITEMS_ID],
            pt_sessions: [ptSession.id],
            campaign_source: campaignSource,
            campaign_medium: campaignMedium,
            campaign_name: campaignName,
            intent_id: setupIntentId,
            discount_code: code,
        }
        console.log(orderParams);
        setPlacingOrder(true);
        createOrder(orderParams)
            .then((result) => {
                if (result.transaction_id) {
                    setTransactionId(result.transaction_id);
                    nextStep();
                }
            })
            .catch((error) => {
                console.error(error, error?.response?.data);
                const messages = error?.response?.data?.errors;
                if (Object.keys(messages).length > 0) {
                    Object.keys(messages).forEach((key) => {
                        alert(messages[key][0])
                    });
                }
                let message = "There was an error placing your order. Please try again.";
                if (error?.response?.data?.message) {
                    message += " " + error.response.data.message;
                }
                setOrderError(message);
            })
            .finally(() => setPlacingOrder(false));
    }, [campaignMedium, campaignName, campaignSource, nextStep, placingOrder, ptSession, setupIntentId, transactionId]);

    const getSubtotals = useCallback((code: string | null = null) => {
        console.log('get subtotals');
        if (loading) {
            console.log('loading subtotals already so bailing');
            return;
        }
        if (placingOrder) {
            console.log('placing order so bailing');
            return;
        }
        setLoading(true);
        getSubscriptionPreview({
            discount_code: code,
            items: [import.meta.env.VITE_ITEMS_ID],
            pt_sessions: [ptSession.id]
        })
            .then((response) => {
                if (code) {
                    setDiscountCode(code);
                }
                setSubscriptionPreview(response.data);
            })
            .finally(() => setLoading(false));
    }, [ptSession]);

    useEffect(() => {
        let code = referralCode;
        console.log('checking if we have a refferal code we need to apply');
        if (!code) {
            console.log('no referral code in props, checking query parameters');
            const queryParameters = new URLSearchParams(window.location.search);
            code = queryParameters.get("discount_code");
        }
        if (code) {
            console.log('setting discount code', code);
            setDiscountCode(code);
        }
        if (code != discountCode) {
            console.log('getting subtotals');
            getSubtotals(code);
        }
    }, [getSubtotals, referralCode]);

    useEffect(() => {
        console.log('checking if we should place the order');
        const queryParameters = new URLSearchParams(window.location.search);
        const redirectStatus = queryParameters.get("redirect_status");

        if (ptSession && setupIntentId && user && !transactionId && redirectStatus === "succeeded" && !orderError) {
            console.log('placing order');
            placeOrder();
        }
    }, [placeOrder, ptSession, setupIntentId, transactionId, user]);

    const date = moment(ptSession.date.toString()).utc();

    const numberFormat = new Intl.NumberFormat("en-GB", {style: "currency", currency: "GBP"});

    useEffect(() => {
        if (!setupIntent && !fetchingSetupIntent) {
            setFetchingSetupIntent(true);
            getSetUpIntent().then((response) => setSetupIntent(response)).catch(e => console.error(e)).finally(() => setFetchingSetupIntent(false));
        }
    }, [discountCode, fetchingSetupIntent, setupIntent]);

    const data = {
        pt_session: ptSession.id.toString(),
        campaign_source: campaignSource ?? "",
        campaign_medium: campaignMedium ?? "",
        campaign_name: campaignName ?? "",
        discount_code: discountCode ?? "",
        referral_code: referralCode ?? "",
    }
    const searchParams = new URLSearchParams(data);
    const returnUrl = window.location.origin + "?" + searchParams.toString();

    let period = subscriptionPreview?.current;
    if (period?.gross_total === 0) {
        period = subscriptionPreview?.next;
    }
    return <>
        <Header title={"One more step..."} subtitle={"Confirm your session by making payment or by applying a trial code"}/>
        <div className={'flex-1'}>
            {!placingOrder &&
                <>
                    <h3 className={'text-xl font-bold mt-5'}>Booking summary</h3>
                    <div className={'flex justify-between mt-3'}>
                        <p>{date.format("dddd, h.mmA ")}</p>
                        <p>{period?.gross_total && numberFormat.format(period.gross_total / 100)}</p>
                    </div>
                    <p>{ptSession.creator.name}</p>

                    <div className={'border-gray-400 border-t my-3'}/>
                    {orderError && <p className={'text-red-600'}>{orderError}</p>}
                    <p>Discount or referral code</p>
                    <form className={'flex'} onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setDiscountFormSubmitted(true);
                        const code = (document.querySelector('input[name=discountCode]') as HTMLInputElement).value.toUpperCase();
                        getSubtotals(code);
                    }}>
                        <input className={`rounded p-4 border text-xs focus:outline-0 flex-1 mr-3 bg-white text-black`} value={discountCode}
                               onChange={(e) => setDiscountCode(e.target.value)}
                               type="text" placeholder={'abc123'} name={'discountCode'} style={{textTransform: "uppercase"}}/>
                        <button className={'border border-black px-5 py-2 text-center text-black rounded w-[100px] uppercase'}>
                            <div className={`text-center w-full`}>
                                {loading ?
                                    <FaSpinner className="animate-spin m-auto w-6 h-6"/>
                                    : 'Apply'}
                            </div>
                        </button>
                    </form>
                    <div className={'py-2'}>
                        {discountCode.length > 0 && subscriptionPreview?.current?.total_discount === 0 && subscriptionPreview?.next?.total_discount === 0 && discountFormSubmitted && !loading ?
                            <p className={'text-red-600'}>This code has expired or is not applicable.</p> : ""
                        }
                        {discountCode.length > 0 && subscriptionPreview && (subscriptionPreview.current.total_discount > 0 || subscriptionPreview?.next.total_discount > 0) && discountFormSubmitted && !loading ?
                            <p className={'text-[#C2FAA5]'}>Your code has been applied.</p> : ""
                        }
                    </div>

                    {subscriptionPreview && subscriptionPreview.current &&
                        <>
                            <p className={'font-bold mt-3 text-xl'}>Your first payment will be:</p>
                            <div className={''}>
                                <div className={'flex justify-between'}>
                                    <p>Subtotal</p>
                                    <p className={'text-right'}>{numberFormat.format(subscriptionPreview.current.gross_total / 100)}</p>
                                </div>
                                <div className={'flex justify-between'}>
                                    <p>Discount</p>
                                    <p className={'text-right ' + (subscriptionPreview.current.total_discount && subscriptionPreview.current.total_discount > 0 && "text-[#C2FAA5]")}>{numberFormat.format(subscriptionPreview.current.total_discount / 100)}</p>
                                </div>
                                <div className={'flex justify-between text-xl font-bold'}>
                                    <p>Total</p>
                                    <p className={'text-right'}>{numberFormat.format(subscriptionPreview.current.net_total / 100)}</p>
                                </div>
                            </div>
                        </>
                    }
                    {subscriptionPreview && subscriptionPreview.next &&
                        <>
                            <p className={'font-bold mt-3 text-xl'}>Then this amount after trial period:</p>
                            <div className={''}>
                                <div className={'flex justify-between'}>
                                    <p>Subtotal</p>
                                    <p className={'text-right'}>{numberFormat.format(subscriptionPreview.next.gross_total / 100)}</p>
                                </div>
                                <div className={'flex justify-between'}>
                                    <p>Discount</p>
                                    <p className={'text-right ' + (subscriptionPreview.next.total_discount && subscriptionPreview.next.total_discount > 0 && "text-[#C2FAA5]")}>{numberFormat.format(subscriptionPreview.next.total_discount / 100)}</p>
                                </div>
                                <div className={'flex justify-between text-xl font-bold'}>
                                    <p>Total</p>
                                    <p className={'text-right'}>{numberFormat.format(subscriptionPreview.next.net_total / 100)}{subscriptionPreview.next.net_total > 0 && <>
                                        <br/><span
                                        className={'inline-block text-base bg-yellow-600 text-yellow-100 px-2 rounded'}>Save over 75% vs. a typical PT session</span></>}</p>
                                </div>
                            </div>
                        </>
                    }

                    {/*<table className={'w-full mt-5'}>*/}
                    {/*    <tbody>*/}
                    {/*    <tr>*/}
                    {/*        <td>Subtotal</td>*/}
                    {/*        <td className={"text-right"}>{period?.gross_total && numberFormat.format(period.gross_total / 100)}</td>*/}
                    {/*    </tr>*/}
                    {/*    <tr>*/}
                    {/*        <td>Discount</td>*/}
                    {/*        <td className={"text-right " + (period?.total_discount && period.total_discount > 0 && "text-[#C2FAA5]")}>*/}
                    {/*            {numberFormat.format(period?.total_discount ? -period.total_discount / 100 : 0)}*/}
                    {/*        </td>*/}
                    {/*    </tr>*/}
                    {/*    <tr>*/}
                    {/*        <td className={'text-xl font-bold'}>Total</td>*/}
                    {/*        <td className={"text-right text-xl font-bold"}>*/}
                    {/*            <span className={'block'}>{numberFormat.format(period?.net_total ? period.net_total / 100 : 0)}</span>*/}
                    {/*            {period && period?.net_total > 0 &&*/}
                    {/*                <span className={'inline-block text-base bg-yellow-600 text-yellow-100 px-2 rounded'}>Save over 75% vs. a typical PT session</span>*/}
                    {/*            }*/}
                    {/*        </td>*/}
                    {/*    </tr>*/}
                    {/*    </tbody>*/}
                    {/*</table>*/}
                </>
            }
            {setupIntent &&
                <div className={'my-5'}>
                    <h3 className={"text-xl font-bold mt-5 mb-3"}>Payment details</h3>
                    <Elements stripe={stripePromise} options={{
                        clientSecret: setupIntent.setup_client_secret,
                        appearance: {
                            theme: 'stripe',

                            variables: {
                                colorPrimary: '#FFFFFF',
                                colorBackground: '#54B4B8',
                                colorText: '#FFFFFF',
                                colorDanger: '#df1b41',
                                fontFamily: 'Ideal Sans, system-ui, sans-serif',
                                // spacingUnit: '2px',
                                // borderRadius: '4px',
                                // See all possible variables below
                            },
                            rules: {
                                '.Input': {
                                    backgroundColor: '#FFFFFF',
                                    color: '#000000',
                                },
                                '.Input::placeholder': {
                                    color: 'rgb(156 163 175)'
                                }
                                // See all supported class names and selector syntax below
                            }
                        }
                    }}>
                        <Checkout setupIntent={setupIntent}
                                  returnUrl={returnUrl}
                                  placingOrder={placingOrder}
                                  complete={nextStep}/>
                    </Elements>
                </div>
            }
            <h4 className={"text-xl"}>Cancellation Policy</h4>
            <p>If a session is cancelled more than 48 hours before it starts, then you will be entitled to a refund. If a session is cancelled less than 48 hours before it
                starts, then you will not be refunded.</p>
        </div>
    </>
}
